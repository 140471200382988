import _toConsumableArray from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "customName", "multiLocationData"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Accordion.style';
import { useYextFetch } from '../../../hooks/useYextFetch';
import LocationFinder from '../LocationFinder';
var AccordionMultiLocation = function AccordionMultiLocation(_ref) {
  var className = _ref.className,
    customName = _ref.customName,
    multiLocationData = _ref.multiLocationData,
    others = _objectWithoutProperties(_ref, _excluded);
  var allLocations = [];
  if (multiLocationData) {
    var yextSingleIds = multiLocationData.yextIds || '';

    // get yext folder id's from the "folder input" field in contentful
    var yextFolderIds = multiLocationData.yextFolderIds || '';

    // call hook to run yext API and return results

    var yextLocationsInformationData =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    yextSingleIds && useYextFetch(yextSingleIds, false);
    var yextFolderInformationData =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    yextFolderIds && useYextFetch(yextFolderIds, true);
    yextLocationsInformationData && yextLocationsInformationData.entities && allLocations.push.apply(allLocations, _toConsumableArray(yextLocationsInformationData.entities));
    yextFolderInformationData && yextFolderInformationData.entities && allLocations.push.apply(allLocations, _toConsumableArray(yextFolderInformationData.entities));
  }
  return allLocations.length > 0 && __jsx(LocationFinder, {
    className: "nva-accordion__location-finder",
    yextLocationsInformationData: allLocations,
    customName: customName
  });
};
export default styled(AccordionMultiLocation).withConfig({
  componentId: "sc-14nmaja-0"
})(["", ";"], styles);